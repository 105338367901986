import { PairingRoundDetailsLayout } from '../components/Pairing/Round';

export function OrganizationPairingRoundDetailsRoute() {
  return <PairingRoundDetailsLayout />;
}

export const Component = OrganizationPairingRoundDetailsRoute;

export function clientLoader() {
  return null;
}
